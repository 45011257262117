import request from '@/request';
const part_url = "/oa/searchaccount";
const getData=()=>{
    let url = part_url;
    return request.get(url,{}, {});
}

const postData=(content)=>{
    let url = part_url;
    return request.post(url,{}, content);
}

export default{
    getData,postData
}